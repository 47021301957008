import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';

import HomePageBlogs from '../components/homePageBlogs';
import Layout from '../components/layout';
import SEO from '../components/seo';
import bottomLeftbg from '../images/images/bottomleftcirclebg.svg';
import bottomRightbg from '../images/images/bottomrightcirclebg.svg';
import finance from '../images/images/finance.png';
import hand_with_heart from '../images/images/hand-with-heart.png';
import thunderbolt from '../images/images/thunderbolt.png';
import topLeftbg from '../images/images/topleftcirclebg.svg';
import topRightbg from '../images/images/toprightcirclebg.svg';
import lamp from '../images/lamp.png';

let getUniqueSectionData = ( data ) => {
  let uniqueSectionArray = [];
  for ( let i = 0; i < data.length; i++ ) {
    if ( !uniqueSectionArray.includes( data[i].node.data_category ) ) {
      uniqueSectionArray.push( data[i].node.data_category );
    }

  }
  return uniqueSectionArray;
};

let getformattedData2 = ( data, sectionsArray ) => {
  let output = {};
  let section;
  let column;
  sectionsArray.forEach( sectionValue => {
    output[sectionValue] = {};
    section = data.filter( ( item ) => item.node.data_category == sectionValue && item.node );
    for ( let col = 1; col < 5; col++ ) {
      output[sectionValue]['column_' + col] = {};
      column = section.filter( ( item ) => item.node.column == col && item.node );
      let len = column.length;
      for ( let j = 0; j < len; j++ ) {
        output[sectionValue]['column_' + col][column[j].node.style_category] = column[j].node;
      }


    }
  } );

  return output;
};

const AboutPage = () => {

  const data = useStaticQuery( graphql`
  query{
    allStrapiPageData(filter: {page_name: {eq: "who_we_are"}}) {
      edges {
        node {
          id
          page_name
          style_category
          content
          attribute_1_key
          attribute_1_value
          attribute_2_key
          attribute_2_value
          column
          data_category
          version
        }
      }
    }
    allStrapiPost(limit: 3, sort: {fields: published_at, order: DESC} , filter:{category: {eq : "BLOG"}}) {
      edges {
        node {
          id
          body
          category
          className
          created_at
          image{
            publicURL
          }
          published_at
          title
          strapiId
          id
        }
      }
    }
  }` );

  const [ fmtData2, setFormattedData ] = useState();

  useEffect( () => {
    let pageData = data.allStrapiPageData.edges;
    let uniqueSectionData = getUniqueSectionData( pageData );
    const fmtData2 = getformattedData2( pageData, uniqueSectionData );
    setFormattedData( fmtData2 );

  }, [data] );

  return (

    <Layout header={'menu'}>
      <SEO
        title="About Get My Bubble | Best Insurance Company in California"
        description="Get My Bubble is one of the more affordable insurance companies, provides home &amp; life insurance for homeowners. Get Quotes Online | 24X7 Call Assistance"
        canonicalUrl='https://www.getmybubble.com/about/'
        keywords='insurance company california, insurance agency california, best insurance company california, local insurance company'
      />

      <div className="flexColumn carrierCommonPage">
        <div className=" company-info-container about-container">
          <h1 className="carrier-plan-heading about-heading" >
            {fmtData2 && fmtData2.section_1.column_1.heading.content}
          </h1>
        </div>


        {/*<div className = "container" style = {{marginTop : "146px", marginBottom : "50px"}}>
            <div className = "row">
                <div className = "col-lg-12 img-text-container">
                   {/*  <img src =   {fmtData2 && fmtData2.section_2.column_1.heading.attribute_1_value

                   alt = {fmtData2 && fmtData2.section_2.column_1.heading.attribute_2_value}></img>

                    <img src = {finance}></img>
                    <div className = "about-align-text ml-5">
                    <h3 className = "carrier-plan-heading">{fmtData2 && fmtData2.section_2.column_1.heading.content}</h3>
                     <p className = "about-para">{fmtData2 && fmtData2.section_2.column_1.text_1.content}</p>

                    <p>{fmtData2 && fmtData2.section_2.column_1.text_2.content}</p>
                  </div>
                </div>
            </div>
        </div>*/}

        <div className="container common-container-space">
          <div className="row h-100 align-items-center about-top-container">
            <div className="col-lg-4 d-flex flexCenter align-items-center title desk-view">

              <img src={finance} className="about-top-image" alt=""/>


            </div>

            <div className="col-lg-8 col-12 content" style={{ padding: '0 23px' }}>
              <div className="d-flex align-items-center flexwrap rightcontent" style={{ marginTop: '27px' }}>
                <h3 className="about-section2-content">{fmtData2 && fmtData2.section_2.column_1.heading.content}</h3>

                <p className="about-para">{fmtData2 && fmtData2.section_2.column_1.text_1.content}</p>

                <p className="about-para">{fmtData2 && fmtData2.section_2.column_1.text_2.content}</p>
              </div>
            </div>

          </div>
        </div>

        <div className="card-bg-containers">
          <img src={topLeftbg} className="topLeftCirclebg" alt="animbgimg"/>

          <img src={bottomLeftbg} className="bottomLeftCirclebg" alt="animbgimg" />

          <img src={topRightbg} className="topRightCirclebg" alt="animbgimg"/>

          <img src={bottomRightbg} className="bottomRightCirclebg" alt="animbgimg" />

          <div className="container about-container-padding common-container-space">
            <div className="row">
              <div className="col-12 about-center-container">
                <p className="align-para-center">
                  {fmtData2 && fmtData2.section_3.column_1.heading_black.content}

                  <span style={{ color: '#ff7007' }}>{fmtData2 && fmtData2.section_3.column_1.heading_orange.content}</span>
                </p>
              </div>

              <div className="align-about-cards">
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-4 col-12 about-card">
                      <div className="img-height">
                        <img className="imgabtdescp" src={thunderbolt} alt="animbgimg"/>
                      </div>

                      <div className="heading-height">
                        <h3 className="align-para-center2" >{fmtData2 && fmtData2.section_3.column_1.title.content}</h3>
                      </div>

                      <p className="about-sec-2-para">
                        {fmtData2 && fmtData2.section_3.column_1.text.content}
                      </p>
                    </div>

                    <div className="col-lg-4 col-12 about-card">
                      <div className="img-height">
                        <img className="imgabtdescp" src={hand_with_heart} alt="animbgimg"/>
                      </div>

                      <div className="heading-height">
                        <h3 className="align-para-center2" >{fmtData2 && fmtData2.section_3.column_2.title.content}</h3>
                      </div>

                      <p className="about-sec-2-para">
                        {fmtData2 && fmtData2.section_3.column_2.text.content}
                      </p>
                    </div>

                    <div className="col-lg-4 col-12 about-card">
                      <div className="img-height">
                        <img className="imgabtdescp" src={lamp} alt="animbgimg" />
                      </div>

                      <div className="heading-height">
                        <h3 className="align-para-center2" >{fmtData2 && fmtData2.section_3.column_3.title.content}</h3>
                      </div>

                      <p className="about-sec-2-para">
                        {fmtData2 && fmtData2.section_3.column_3.text.content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-insuranceCovers flexRow justify-content-center">
          {/* <div className="flexColumn containerCustomStyles">
            <div className="section-title">
              {fmtData2 && fmtData2.section_4.column_1.heading.content}
            </div>
            <div className="flexRow common-container-space flexwrap">
              {fmtData2 &&
                Object.keys(fmtData2.section_4).map(e => <div key={e} className="coverCardText">
                  <div className="cardTitleText">
                    {fmtData2.section_4[e].card_title.content}
                  </div>
                  <div className="about-card-text">
                    {fmtData2.section_4[e].card_text.content}
                  </div>
                </div>)
              }
            </div>
          </div> */}

          <div className="container common-container-space">
            <div className="row">
              <div className="col-12 costom-padding16">
                <div className="section-title">
                  {fmtData2 && fmtData2.section_4.column_1.heading.content}
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  {fmtData2 &&
                    Object.keys( fmtData2.section_4 ).map( e => ( <div key={e} className="col-lg-6 col-md-12 col-sm-12 col-12 costom-padding16">
                      <div className="coverCardText">
                        <div className="cardTitleText">
                          {fmtData2.section_4[e].card_title.content}
                        </div>

                        <div className="about-card-text">
                          {fmtData2.section_4[e].card_text.content}
                        </div>
                      </div>
                    </div> ) )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*    <div className="container common-container-space">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6 d-flex flexCenter align-items-center title desk-view">
                <div className="img-bg">
                  <img
                    src={log_out}></img>

                </div>
              </div>
              <div className="col-lg-6 col-12 content">
                <div className="d-flex align-items-center flexwrap rightcontent align-horizontal-center">
                  <div>
                     <h3 className = "carrier-plan-heading">See if we’re in your area</h3>
                     <p className = "about-para align-text-horizontal-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi gravida rutrum dui sit amet viverra.</p>
                     <div className="input-group">
                     <input className="form-control py-2 rounded-pill mr-1 pr-5" type="search" value="Enter your current residence zip"></input>
                    </div>
                     <button className="carrier-plan-button carrier-btn-text mt-4">
                          submit
                      </button>
                  </div>
                </div>
              </div>

            </div>
        </div> */}


        <div className="blog-box">
          <div className="container common-container-space">
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="carrier-plan-heading">Check out Bubble’s insights</h3>
              </div>

              <div className="col-12">
                <div className="card-deck">
                  <HomePageBlogs/>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="plansForYou">
          <div className="orangeBackGround">
            <div>
              Find out which plan is right for you
            </div>

            <div className="customizedPlan">
              Policies are customized according to your needs.
              We have simplified the process to help you find the right policy that fits your budget and loved ones.
            </div>

            <Link to="/" type="button" className="btnWhite">
              Get your quote now
            </Link>
          </div>
        </div>

      </div>
    </Layout> );

};

export default AboutPage;
